import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

const Help = () => {
  return (
    <div>
      <h2 className="text-2xl font-sweetsanspro font-semibold mb-11">Help</h2>
      <div className="font-bold text-lg">
        Any questions or need technical help?
      </div>
      <div className="text-sm mt-5 text-gray-900">
        Send us an email at{" "}
        <a
          className="underline"
          rel="noreferrer"
          href="mailto:info@dorothyai.com"
          target="_blank"
        >
          info@dorothyai.com
        </a>{" "}
        or click the button below.
      </div>
      <div className="mt-5">
        <a
          className="h-10 inline-flex items-center justify-center gap-2 rounded-lg text-sm text-white bg-dorothy-orange w-44 hover:bg-dorothy-dark-orange"
          rel="noreferrer"
          href="mailto:info@dorothyai.com"
          target="_blank"
        >
          <EnvelopeIcon className="w-4 h-4" />
          Email Us
        </a>
      </div>
    </div>
  );
};

export default Help;
