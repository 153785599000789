import Select from "react-select";
import TooltipInfoIcon from "../tooltipinfoicon/TooltipInfoIcon";

const MultiSelect = ({
  field,
  form,
  data,
  multiple = false,
  placeholder = "Select",
  label,
  required,
  tooltip,
  tooltipId,
  error,
  errorMsg,
  isClearable,
  tabIndex,
}: {
  field: any;
  form: any;
  data: any;
  multiple?: boolean;
  placeholder?: string;
  label?: string;
  required?: boolean;
  tooltip?: boolean;
  tooltipId?: string;
  error?: boolean;
  errorMsg?: string;
  isClearable?: boolean;
  tabIndex?: number;
}) => {
  function onChange(option: any) {
    form.setFieldValue(
      field.name,
      option ? option.map((item: any) => item.value) : []
    );
  }

  const getValue = () => {
    if (data) {
      return multiple
        ? data.filter((option: any) => field.value.indexOf(option.value) >= 0)
        : data.find((option: any) => option.value === field.value);
    } else {
      return multiple ? [] : "";
    }
  };

  return (
    <div className="mb-5">
      {label && (
        <span className="mb-1 text-sm block">
          {label} {required && <b className="text-error">*</b>}
          {tooltip && <TooltipInfoIcon tooltipId={tooltipId} classes="ml-3" />}
        </span>
      )}

      {multiple ? (
        <Select
          className={`react-select-input ${error && "rs-error"} text-sm`}
          name={field.name}
          value={getValue()}
          onChange={onChange}
          options={data}
          isMulti={true}
          placeholder={placeholder}
          isClearable={isClearable}
          tabIndex={tabIndex}
        />
      ) : (
        <Select
          className={`react-select-input ${error && "rs-error"} text-sm`}
          options={data}
          name={field.name}
          value={
            data ? data.find((option: any) => option.value === field.value) : ""
          }
          onChange={(option) => form.setFieldValue(field.name, option?.value)}
          onBlur={field.onBlur}
          placeholder={placeholder}
          isClearable={isClearable}
          tabIndex={tabIndex}
        />
      )}

      {error && errorMsg ? (
        <span className="block text-error text-xs mt-1">{errorMsg}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default MultiSelect;
