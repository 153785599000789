import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { INoveltySearch } from "../../features/noveltySearchApi";
import { dateFormatOptions } from "../../utils/consts";
import { dateFormatter } from "../../utils/helpers";
import { FreedomIcon, Label, NoveltyIcon, QueueIcon } from "../shared";

interface ISearchItem {
  label?: string;
  type?: string;
  data: INoveltySearch;
  onClick: any;
}

const SearchItem: React.FC<ISearchItem> = ({
  label,
  type = "novelty",
  data,
  onClick,
}) => {
  const date = data.createdAt
    ? new Date(data.createdAt).toLocaleDateString("en-US", dateFormatOptions)
    : "(date_error)";

  let results: any = "";
  let clickableStyles: string = "";
  if (data.status === "ready") {
    clickableStyles = "cursor-pointer hover:shadow-lg";
    results = (
      <>
        <span>{data.totalSaved ? data.totalSaved : "0"} saved</span>
        <span>{data.totalResult ? data.totalResult : "0"} result</span>
      </>
    );
  }

  return (
    <div
      className={`bg-white flex rounded-xl p-5 text-xs ${clickableStyles}`}
      onClick={data.status === "ready" ? onClick : null}
    >
      <div className="flex-none pr-4">
        {type === "freedom" ? <FreedomIcon /> : <NoveltyIcon />}
      </div>
      <div className="flex-1 font-normal flex flex-col justify-between">
        <div>
          <div className="mb-1 flex flex-row justify-between">
            <div>
              <span className="font-light opacity-50">Search ID:</span>{" "}
              {data.id}
            </div>
            <div>
              {dateFormatter(data.createdAt, "frontend")}{" "}
              {data.createdAt && (
                <span className="font-light opacity-50">
                  {new Date(data.createdAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              )}
            </div>
          </div>
          <div className="font-bold break-all">{label}</div>
        </div>
        <div>
          <div className="font-normal mt-3">{date}</div>
          <div className="mt-3 flex justify-between items-center">
            <div className="mr-1">
              {data.status === "inQueue" ? (
                <Label color="orange" icon={<QueueIcon />}>
                  In Queue
                </Label>
              ) : (
                <Label
                  color="darkgray"
                  icon={<CheckCircleIcon className="w-4 h-4" />}
                >
                  Ready
                </Label>
              )}
            </div>
            <div className="flex gap-2 text-right">{results}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
