import React, { useState } from "react";
import {
  ArrowTopRightOnSquareIcon,
  BookmarkIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkSolidIcon } from "@heroicons/react/24/solid";
import { IResult } from "../../features/resultsApi";
import { Button, DownloadPdfIcon, LinkButton, Slider } from "../shared";
import { Popover } from "@headlessui/react";
import { dateFormatOptions } from "../../utils/consts";

const ResultCard = ({
  data,
  onSaveChange,
  onCardDetail,
}: {
  data?: IResult;
  onSaveChange?: any;
  onCardDetail?: any;
}) => {
  const [more, setMore] = useState(false);

  const filingDate = data?.filingDate
    ? new Date(data.filingDate).toLocaleDateString("en-US", dateFormatOptions)
    : "----";

  const publicationDate = data?.publicationDate
    ? new Date(data.publicationDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      )
    : "----";

  const issueDate = data?.issueDate
    ? new Date(data.issueDate).toLocaleDateString("en-US", dateFormatOptions)
    : "----";

  const downloadPDF = (url: any, file_name: any) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = file_name + ".pdf";
    link.click();
  };

  //assignee calculate
  const assigneeCount = data?.assignee ? data.assignee.length : 0;
  const assignee =
    assigneeCount === 0 ? (
      "----"
    ) : assigneeCount === 1 ? (
      <div className="truncate">{data?.assignee[0]}</div>
    ) : (
      <>
        <span className="truncate mr-1">{data?.assignee[0]}</span>
        <Popover className="relative inline-block">
          <Popover.Button>
            <LinkButton onClick={() => {}}>(+{assigneeCount - 1})</LinkButton>
          </Popover.Button>
          <Popover.Panel className="absolute text-xs z-10 shadow-md rounded-lg p-6 bg-white border lg:-translate-x-1/2">
            {({ close }) => (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="mr-3 font-bold text-lg whitespace-nowrap">
                    Assignee
                  </h3>
                  <button
                    onClick={() => close()}
                    className="w-6 h-6 bg-dorothy-gray rounded items-center justify-center inline-flex hover:bg-gray-300"
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="max-h-72 overflow-y-auto">
                  {data?.assignee.map((d, i) => {
                    return (
                      <div key={i} className="mt-2">
                        {d}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Popover.Panel>
        </Popover>
      </>
    );

  //inventors calculate
  const inventorsCount = data?.inventors ? data.inventors.length : 0;
  const inventors =
    inventorsCount === 0 ? (
      "----"
    ) : inventorsCount === 1 ? (
      <div className="truncate">{data?.inventors[0]}</div>
    ) : (
      <>
        <span className="truncate mr-1">{data?.inventors[0]}</span>
        <Popover className="relative inline-block">
          <Popover.Button>
            <LinkButton onClick={() => {}}>(+{inventorsCount - 1})</LinkButton>
          </Popover.Button>
          <Popover.Panel className="absolute text-xs z-10 shadow-md rounded-lg p-6 bg-white border left-full lg:left-1/2 lg:-translate-x-1/2 -translate-x-full">
            {({ close }) => (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="mr-3 font-bold text-lg whitespace-nowrap">
                    Inventors
                  </h3>
                  <button
                    onClick={() => close()}
                    className="w-6 h-6 bg-dorothy-gray rounded items-center justify-center inline-flex hover:bg-gray-300"
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="max-h-72 overflow-y-auto">
                  {data?.inventors.map((d, i) => {
                    return (
                      <div key={i} className="mt-2">
                        {d}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Popover.Panel>
        </Popover>
      </>
    );

  //primary classification calculate
  const primaryClassificationCount = data?.primaryClassification
    ? data.primaryClassification.length
    : 0;
  const primaryClassification =
    primaryClassificationCount === 0 ? (
      "----"
    ) : primaryClassificationCount === 1 ? (
      <div className="truncate">{data?.primaryClassification[0]}</div>
    ) : (
      <>
        <span className="truncate mr-1">{data?.primaryClassification[0]}</span>
        <Popover className="relative inline-block">
          <Popover.Button>
            <LinkButton onClick={() => {}}>
              (+{primaryClassificationCount - 1})
            </LinkButton>
          </Popover.Button>
          <Popover.Panel className="absolute text-xs z-10 shadow-md rounded-lg p-6 bg-white border left-full lg:left-1/2 lg:-translate-x-1/2 -translate-x-full">
            {({ close }) => (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="mr-3 font-bold text-lg whitespace-nowrap">
                    Primary Classification
                  </h3>
                  <button
                    onClick={() => close()}
                    className="w-6 h-6 bg-dorothy-gray rounded items-center justify-center inline-flex hover:bg-gray-300"
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="max-h-72 overflow-y-auto">
                  {data?.primaryClassification.map((d, i) => {
                    return (
                      <div key={i} className="mt-2">
                        {d}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Popover.Panel>
        </Popover>
      </>
    );

  return (
    <div className="h-30 border rounded-xl mb-4 bg-white">
      <div className="p-6 bg-gray-50 rounded-t-xl">
        <div className="text-gray-600">
          Publication No.
          <span className="ml-3 text-black">{data?.publicationNo}</span>
        </div>
        <div className="flex gap-4 flex-col lg:flex-row">
          <div className="flex-auto">
            <div className="font-medium text-sm my-3">{data?.title}</div>
            <div className="text-gray-600">
              Relevancy
              <span className="ml-3 mr-6 text-black">
                {data?.relevancy &&
                  (parseFloat(data.relevancy) * 100).toFixed(0)}
                %
              </span>
              Status<span className="mx-3 text-black">{data?.status}</span>
            </div>
          </div>
          <div className="lg:shrink-0 lg:grow-0 lg:basis-52">
            <div className="flex flex-row items-center justify-start lg:justify-between lg:items-stretch gap-3 lg:flex-col">
              <div className="">
                <Button variant="outlined" full={true} onClick={onSaveChange}>
                  {data?.isSaved ? (
                    <>
                      <BookmarkSolidIcon className="w-4 h-4 mr-2" />
                      Saved
                    </>
                  ) : (
                    <>
                      <BookmarkIcon className="w-4 h-4 mr-2" />
                      Save
                    </>
                  )}
                </Button>
              </div>
              <div className="flex justify-start lg:justify-between gap-3">
                <LinkButton
                  onClick={() =>
                    window.open(
                      "https://patents.google.com/patent/" +
                        data?.publicationNo,
                      "_blank"
                    )
                  }
                >
                  <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" /> View
                </LinkButton>
                {data?.downloadLink && (
                  <LinkButton
                    onClick={() =>
                      downloadPDF(data.downloadLink, data.publicationNo)
                    }
                  >
                    <DownloadPdfIcon />
                    <span className="ml-1">Download</span>
                  </LinkButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 p-6 lg:flex-row flex-col">
        <div className="flex-auto">
          <div className="grid grid-cols-3 gap-2">
            <div>
              <h5 className="font-bold">Filing</h5>
              <div className="mt-2">{filingDate}</div>
            </div>
            <div>
              <h5 className="font-bold">Publication</h5>
              <div className="mt-2">{publicationDate}</div>
            </div>
            <div>
              <h5 className="font-bold">Issue</h5>
              <div className="mt-2">{issueDate}</div>
            </div>
          </div>

          <div
            className={`${
              more ? "grid" : "hidden"
            } lg:grid grid-cols-3 mt-5 border-t pt-5 gap-2`}
          >
            <div>
              <h5 className="font-bold">Assignee</h5>
              <div className="mt-2 whitespace-nowrap flex justify-start text-xs">
                {assignee}
              </div>
            </div>
            <div>
              <h5 className="font-bold">Inventors</h5>
              <div className="mt-2 whitespace-nowrap flex justify-start text-xs">
                {inventors}
              </div>
            </div>
            <div>
              <h5 className="font-bold truncate">Primary Classification</h5>
              <div className="mt-2 whitespace-nowrap flex justify-start text-xs">
                {primaryClassification}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            more ? "block" : "hidden"
          } lg:block border-y border-gray-200 py-4 lg:border-0 lg:py-0 shrink-0 grow-0 basis-52`}
        >
          <h5 className="font-bold">Additional Details</h5>

          {data?.citedDocuments && data.citedDocuments.length > 0 ? (
            <div className="mt-4">
              <LinkButton
                onClick={onCardDetail({
                  type: "citedDocuments",
                  title: "Cited Documents",
                  description: (
                    <>
                      View Publication <b>{data?.publicationNo}</b>
                    </>
                  ),
                  data: data?.citedDocuments,
                })}
              >
                <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />{" "}
                {data.citedDocuments.length} Cited Documents
              </LinkButton>
            </div>
          ) : (
            ""
          )}

          {data?.drawingsFigures && data.drawingsFigures.length > 0 ? (
            <div className="mt-2">
              <LinkButton
                onClick={onCardDetail({
                  type: "drawingsFigures",
                  title: "Drawings / Figures",
                  description: (
                    <>
                      View Publication <b>{data?.publicationNo}</b>
                    </>
                  ),
                  data: data?.drawingsFigures,
                })}
              >
                <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />{" "}
                {data.drawingsFigures.length} Drawings / Figures
              </LinkButton>
            </div>
          ) : (
            ""
          )}

          <div className="mt-2">
            <LinkButton
              onClick={onCardDetail({
                type: "abstract",
                title: "Abstract",
                description: (
                  <>
                    View Publication <b>{data?.publicationNo}</b>
                  </>
                ),
                data: data?.abstract,
              })}
            >
              <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" /> Abstract
            </LinkButton>
          </div>

          {data?.familyMembers && data.familyMembers.length > 0 ? (
            <div className="mt-2">
              <LinkButton
                onClick={onCardDetail({
                  type: "familyMembers",
                  title: "Related Documents",
                  description: (
                    <>
                      View Publication <b>{data?.publicationNo}</b>
                    </>
                  ),
                  data: data?.familyMembers,
                })}
              >
                <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />{" "}
                {data.familyMembers.length} Related Documents
              </LinkButton>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {data?.keyPhrases && data.keyPhrases.length > 1 ? (
        <div className={`${more ? "block" : "hidden"} lg:block p-6 pt-0`}>
          <h5 className="font-bold">Key Phrases</h5>
          <div className="mt-3">
            <Slider data={data.keyPhrases} />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="block p-6 pt-0 lg:hidden">
        <LinkButton onClick={() => setMore((m) => !m)}>
          {more ? "Hide Details" : "Show More Details"}
        </LinkButton>
      </div>
    </div>
  );
};

export default ResultCard;
