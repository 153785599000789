import React from "react";

const TermsOfUse = () => {
  return (
    <div>
      <h2 className="text-2xl font-sweetsanspro font-semibold mb-11">Terms of Use</h2>
      <div className="font-bold text-lg my-6">What our terms of use covers</div>
      <div className="text-sm text-gray-900">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Culpa quasi
        hic dolor, aliquid impedit similique autem corrupti laborum harum
        consectetur asperiores nisi corporis repellat ea perspiciatis, quas quo
        rem voluptatem.
      </div>
      <div className="font-bold text-lg my-6">Lorem ipsum dolar</div>
      <div className="text-sm text-gray-900">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Culpa quasi
        hic dolor, aliquid impedit similique autem corrupti laborum harum
        consectetur asperiores nisi corporis repellat ea perspiciatis, quas quo
        rem voluptatem.
      </div>
    </div>
  );
};

export default TermsOfUse;
