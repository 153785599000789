import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles.scss";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
    <Toaster
      position="bottom-left"
      toastOptions={{
        duration: 4000,
        className: "rounded-lg !text-white w-auto !bg-gray-900 md:w-96",
      }}
      containerClassName="lg:!inset-x-72 lg:!inset-y-12"
    />
  </React.StrictMode>
);
