import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  ClipboardDocumentListIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

const Menu = ({ onClose, onLogout }: { onClose?: any; onLogout?: any }) => {
  const { pathname } = useLocation();

  return (
    <>
      <NavLink
        to="/projects"
        className={({ isActive }) =>
          isActive && pathname === "/projects"
            ? "menu-link-active"
            : "menu-link"
        }
        onClick={onClose}
      >
        <ClipboardDocumentListIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Projects
      </NavLink>
      <NavLink
        to="/help"
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <QuestionMarkCircleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Help
      </NavLink>
      <span className="menu-link cursor-pointer" onClick={onLogout}>
        <ArrowRightOnRectangleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Logout
      </span>
    </>
  );
};

export default Menu;
