import React, { useReducer } from "react";
import { useParams } from "react-router-dom";
import ProjectDetailCard from "../components/projectdetailcard/ProjectDetailCard";
import SearchItems from "../components/searchitems/SearchItems";
import {
  Button,
  FreedomIcon,
  Loader,
  Modal,
  NoveltyIcon,
  StrategicAlignmentIcon,
  Unauthorized,
} from "../components/shared";
import { useGetNoveltySearchesQuery } from "../features/noveltySearchApi";
import { useGetProjectQuery } from "../features/projectsApi";
import SearchInProgress from "../components/modalcontents/SearchInProgress";
import SearchTypeItem from "../components/modalcontents/SearchTypeItem";
import NoveltySearchForm from "../components/modalcontents/NoveltySearchForm";
import FreedomSearchForm from "../components/modalcontents/FreedomSearchForm";
import { useGetFreedomSearchesQuery } from "../features/freedomSearchApi";
import { getPermissions } from "../utils/helpers";
import StrategicAlignmentSearchForm from "../components/modalcontents/StrategicAlignmentSearchForm";
import RequestSubmitted from "../components/modalcontents/RequestSubmitted";

const initialModalStates = {
  projectModal: false,
  searchTypeModal: false,
  noveltyModal: false,
  freedomModal: false,
  strategicAlignmentModal: false,
  infoModal: false,
  emailInfoModal: false,
};

const modalsReducer = (state: any, action: any) => {
  switch (action) {
    case "open_project": {
      return { ...state, projectModal: true };
    }
    case "close_project": {
      return { ...state, projectModal: false };
    }
    case "open_search_type": {
      return { ...state, searchTypeModal: true };
    }
    case "close_search_type": {
      return { ...state, searchTypeModal: false };
    }
    case "open_novelty": {
      return { ...state, noveltyModal: true };
    }
    case "close_novelty": {
      return { ...state, noveltyModal: false };
    }
    case "open_freedom": {
      return { ...state, freedomModal: true };
    }
    case "close_freedom": {
      return { ...state, freedomModal: false };
    }
    case "open_strategic_alignment": {
      return { ...state, strategicAlignmentModal: true };
    }
    case "close_strategic_alignment": {
      return { ...state, strategicAlignmentModal: false };
    }
    case "open_info": {
      return { ...state, infoModal: true };
    }
    case "close_info": {
      return { ...state, infoModal: false };
    }
    case "open_email_info": {
      return { ...state, emailInfoModal: true };
    }
    case "close_email_info": {
      return { ...state, emailInfoModal: false };
    }
  }
  throw Error("Unknown action: " + action);
};

const ProjectDetail = () => {
  const [modals, dispatch] = useReducer(modalsReducer, initialModalStates);

  let { projectId } = useParams();
  const {
    data: project,
    isLoading,
    isError,
    error,
  } = useGetProjectQuery(projectId, {
    refetchOnMountOrArgChange: true,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  const permissions = getPermissions();
  const resultPageSize = permissions.length === 1 ? 10 : 4;

  return (
    <>
      {permissions.length > 0 ? (
        <div>
          <div className="flex md:flex-row flex-col justify-between mb-6">
            <div>
              <h2 className="text-2xl font-sweetsanspro font-semibold w-full break-all">
                {project?.title}
              </h2>
              <span
                className="mt-2 mb-4 block text-dorothy-orange cursor-pointer xl:hidden"
                onClick={() => dispatch("open_project")}
              >
                Show project details
              </span>
            </div>
            <div className="full-button">
              <Button onClick={() => dispatch("open_search_type")}>
                New Search
              </Button>
            </div>
          </div>

          <div className="flex flex-row">
            <div className="flex-1 lg:pr-6">
              {permissions.includes("novelty") && (
                <SearchItems
                  type="novelty"
                  title="Novelty Searches"
                  projectId={projectId}
                  api={useGetNoveltySearchesQuery}
                  pageSize={resultPageSize}
                />
              )}

              {permissions.includes("freedom") && (
                <SearchItems
                  type="freedom"
                  title="Freedom Searches"
                  projectId={projectId}
                  api={useGetFreedomSearchesQuery}
                  pageSize={resultPageSize}
                />
              )}
            </div>
            <div className="flex-none w-72 hidden xl:block">
              <ProjectDetailCard data={project} />
            </div>
          </div>

          {/* Project detail modal for mobile view */}
          <Modal
            isShow={modals.projectModal}
            closeHandler={() => dispatch("close_project")}
            closeButton={true}
            title="Project Details"
          >
            <ProjectDetailCard data={project} type="modal" />
          </Modal>

          {/* Select search type modal */}
          <Modal
            isShow={modals.searchTypeModal}
            closeHandler={() => dispatch("close_search_type")}
            title="Select Type of Search"
            description="Please select the product you would like to use to perform the search."
            closeButton={true}
          >
            <div>
              {permissions.includes("novelty") && (
                <SearchTypeItem
                  type="novelty"
                  title="Novelty Search"
                  description="Perform an AI powered search to determine patentability of a new idea."
                  clickHandler={() => {
                    dispatch("close_search_type");
                    setTimeout(() => {
                      dispatch("open_novelty");
                    }, 1000);
                  }}
                />
              )}
              {permissions.includes("freedom") && (
                <SearchTypeItem
                  type="freedom"
                  title="Freedom Search"
                  description="Perform an AI powered search to assess freedom to operate."
                  clickHandler={() => {
                    dispatch("close_search_type");
                    setTimeout(() => {
                      dispatch("open_freedom");
                    }, 1000);
                  }}
                />
              )}

              {permissions.includes("strategicAlignment") && (
                <SearchTypeItem
                  type="strategicAlignment"
                  title="Strategic Alignment (Beta)"
                  description="Get global strategic insights on your patent. Results may take a few days while in Beta."
                  clickHandler={() => {
                    dispatch("close_search_type");
                    setTimeout(() => {
                      dispatch("open_strategic_alignment");
                    }, 1000);
                  }}
                />
              )}
            </div>
          </Modal>

          {/* Novelty search modal */}
          <Modal
            isShow={modals.noveltyModal}
            closeHandler={() => dispatch("close_novelty")}
            title="Novelty Search"
            closeButton={true}
            titleIcon={<NoveltyIcon />}
          >
            <NoveltySearchForm
              nextHandler={() => {
                dispatch("close_novelty");
                setTimeout(() => {
                  dispatch("open_info");
                }, 1000);
              }}
              backHandler={() => {
                dispatch("close_novelty");
                setTimeout(() => {
                  dispatch("open_search_type");
                }, 1000);
              }}
              projectId={projectId || "none"}
            />
          </Modal>

          {/* Freedom search modal */}
          <Modal
            isShow={modals.freedomModal}
            closeHandler={() => dispatch("close_freedom")}
            title="Freedom Search"
            titleIcon={<FreedomIcon />}
            closeButton={true}
          >
            <FreedomSearchForm
              nextHandler={() => {
                dispatch("close_freedom");
                setTimeout(() => {
                  dispatch("open_info");
                }, 1000);
              }}
              backHandler={() => {
                dispatch("close_freedom");
                setTimeout(() => {
                  dispatch("open_search_type");
                }, 1000);
              }}
              projectId={projectId || "none"}
            />
          </Modal>

          {/* Strategic Alignment search modal */}
          <Modal
            isShow={modals.strategicAlignmentModal}
            closeHandler={() => dispatch("close_strategic_alignment")}
            title="Strategic Alignment (Beta)"
            titleIcon={<StrategicAlignmentIcon />}
            description="This form will send an request that will be fulfilled by our team in the next few days."
            closeButton={true}
          >
            <StrategicAlignmentSearchForm
              nextHandler={() => {
                dispatch("close_strategic_alignment");
                setTimeout(() => {
                  dispatch("open_email_info");
                }, 1000);
              }}
              backHandler={() => {
                dispatch("close_strategic_alignment");
                setTimeout(() => {
                  dispatch("open_search_type");
                }, 1000);
              }}
              projectId={projectId || "none"}
            />
          </Modal>

          {/* General search initiated info modal */}
          <Modal
            type="simple"
            isShow={modals.infoModal}
            closeHandler={() => dispatch("close_info")}
            overlayClose={false}
          >
            <SearchInProgress closeHandler={() => dispatch("close_info")} />
          </Modal>

          {/* Strategic alignment submitted info modal */}
          <Modal
            type="simple"
            isShow={modals.emailInfoModal}
            closeHandler={() => dispatch("close_email_info")}
            overlayClose={false}
          >
            <RequestSubmitted
              closeHandler={() => dispatch("close_email_info")}
            />
          </Modal>
        </div>
      ) : (
        <div className="">
          You don't have any permission. Please contact the administrator.
        </div>
      )}
    </>
  );
};

export default ProjectDetail;
