import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { projectsApi } from "./projectsApi";
import { noveltySearchApi } from "./noveltySearchApi";
import { freedomSearchApi } from "./freedomSearchApi";
import { strategicAlignmentSearchApi } from "./strategicAlignmentSearchApi";
import { resultsApi } from "./resultsApi";

export const store = configureStore({
  reducer: {
    [projectsApi.reducerPath]: projectsApi.reducer,
    [noveltySearchApi.reducerPath]: noveltySearchApi.reducer,
    [freedomSearchApi.reducerPath]: freedomSearchApi.reducer,
    [strategicAlignmentSearchApi.reducerPath]:
      strategicAlignmentSearchApi.reducer,
    [resultsApi.reducerPath]: resultsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      projectsApi.middleware,
      noveltySearchApi.middleware,
      freedomSearchApi.middleware,
      strategicAlignmentSearchApi.middleware,
      resultsApi.middleware
    ),
});

setupListeners(store.dispatch);
