import React from "react";
import { useParams } from "react-router-dom";
import { searchTypes } from "../utils/consts";
import Freedom from "../components/results/Freedom";
import Novelty from "../components/results/Novelty";
import Error from "./Error";

const Result = () => {
  let { result }: any = useParams();
  if (!searchTypes.includes(result)) {
    return (
      <Error description="Message: Search type must be novelty or freedom." />
    );
  }

  return <>{result === "novelty" ? <Novelty /> : <Freedom />}</>;
};

export default Result;
