import React from "react";
import Select from "react-select";
import { TooltipInfoIcon } from "../";

export interface IReactselectItem {
  value: string;
  label: string;
}

export interface IReactselectList extends Array<IReactselectItem> {}

interface IReactselectProps {
  data?: IReactselectItem | any;
  defaultValue?: any;
  name: string;
  label?: string;
  multiple?: boolean;
  menuPosition?: string | any;
  isLoading?: boolean;
  onChange?: any;
  isClearable?: boolean;
  required?: boolean;
  value?: any;
  selectRef?: any;
  tooltip?: boolean;
  tooltipId?: string;
  tabIndex?: number;
}

const ReactSelect: React.FC<IReactselectProps> = ({
  data,
  defaultValue,
  name,
  label,
  multiple = false,
  menuPosition = "bottom",
  isLoading,
  onChange,
  isClearable = true,
  required,
  value,
  selectRef,
  tooltip = false,
  tooltipId,
  tabIndex,
}) => {
  return (
    <div className="mb-5">
      {label && (
        <span className="mb-1 text-sm block">
          {label} {required && <b className="text-error">*</b>}
          {tooltip && <TooltipInfoIcon tooltipId={tooltipId} classes="ml-3" />}
        </span>
      )}
      <Select
        isMulti={multiple}
        name={name}
        options={data}
        className={`react-select-input text-sm`}
        menuPlacement={menuPosition}
        defaultValue={defaultValue}
        isLoading={isLoading}
        onChange={onChange}
        isClearable={isClearable}
        ref={selectRef}
        value={value}
        tabIndex={tabIndex}
        //getOptionLabel={(op:any) => `${op['value']}`}
      />
    </div>
  );
};

export default ReactSelect;
