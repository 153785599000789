import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./style.scss";
import infoContents from "./TooltipContents";

interface ITooltipInfoIcon {
  tooltipId?: string;
  classes?: string;
}

const TooltipInfoIcon: React.FC<ITooltipInfoIcon> = ({
  tooltipId,
  classes,
}) => {
  let tipId = tooltipId;
  if (tooltipId && tooltipId.includes("___")) {
    tipId = tooltipId.split("___")[0];
  }

  const tooltipClickHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
  };
  return (
    <>
      <span
        onClick={tooltipClickHandler}
        id={tooltipId}
        className={`text-xxs font-light leading-2 bg-novelty inline-block w-4 align-middle h-4 p-1 rounded-sm text-white text-center ${classes}`}
      >
        i
      </span>
      {tipId && (
        <ReactTooltip
          anchorId={tooltipId}
          style={{
            backgroundColor: "#222844",
            opacity: 1,
            color: "#FFFFFF",
            zIndex: 55,
            borderRadius: "6px",
            padding: "20px",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: 1.4,
            textAlign: "left",
          }}
          className="md:max-w-sm tooltip-mobile font-montserrat"
          place="left"
          html={infoContents[tipId]}
        />
      )}
    </>
  );
};

export default TooltipInfoIcon;
