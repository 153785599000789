import React from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import ProjectForm from "../components/projectform/ProjectForm";
import { useGetOwnersQuery, useGetProjectQuery } from "../features/projectsApi";
import { prepareOwners } from "../utils/helpers";
import Countries from "../assets/countries.json";
import { Loader, Unauthorized } from "../components/shared";

const EditProject = () => {
  let { projectId } = useParams();
  const { data: project, isLoading, isError, error } = useGetProjectQuery(projectId);
  const { data: owners, isLoading: ownerIsLoading } = useGetOwnersQuery("");

  const ownerList = prepareOwners(owners);
  const selectedOwner = ownerList.filter(
    (o: any) => o.value === project?.ownerId
  )[0];

  //preparing headquarters, manifature and primary markets data
  let headQuartersData: any = [];
  if (project?.headQuarters.length) {
    headQuartersData = Countries.filter((item) =>
      project.headQuarters.includes(item.value)
    );
  }

  let manufactureData: any = [];
  if (project?.manufacture.length) {
    manufactureData = Countries.filter((item) =>
      project.manufacture.includes(item.value)
    );
  }

  let primaryMarketsData: any = [];
  if (project?.primaryMarkets.length) {
    primaryMarketsData = Countries.filter((item) =>
      project.primaryMarkets.includes(item.value)
    );
  }

  if (isLoading || ownerIsLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  return (
    <div>
      <div className="flex justify-between mb-6">
        <h2 className="text-2xl font-sweetsanspro font-semibold flex items-center">
          <PencilSquareIcon className="w-6 h-6 mr-3" /> Edit Project
        </h2>
      </div>
      <div className="max-w-2xl">
        <ProjectForm
          type="edit"
          data={project}
          owners={ownerList}
          selectedOwner={selectedOwner}
          headQuartersData={headQuartersData}
          manufactureData={manufactureData}
          primaryMarketsData={primaryMarketsData}
        />
      </div>
    </div>
  );
};

export default EditProject;
