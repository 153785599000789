import { Fragment, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { Breadcrumbs } from "../components/shared";
import Logo from "../assets/img/dorothyai-logo-white.svg";
import Menu from "./Menu";
import { getFirstChars } from "../utils/helpers";
import SubMenu from "./SubMenu";

const Main = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo] = useState(authState?.idToken?.claims);

  const logoutHandler = () => {
    setSidebarOpen(false);
    oktaAuth.signOut();
  };

  return (
    <>
      <div>
        {/* mobile responsive sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={() => setSidebarOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-sidebar flex-1 flex-col bg-dark-blue">
                  <div className="flex flex-shrink-0 p-8">
                    <img className="h-8 w-auto" src={Logo} alt="Dorothy AI" />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto px-6 pb-4 font-bold">
                    <nav>
                      <Menu
                        onClose={() => setSidebarOpen(false)}
                        onLogout={logoutHandler}
                      />
                    </nav>
                  </div>
                  <SubMenu onClose={() => setSidebarOpen(false)} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto bg-dark-blue">
            <div className="flex flex-shrink-0 items-center p-8">
              <img className="h-8 w-auto" src={Logo} alt="Dorothy AI" />
            </div>
            <div className="mt-5 flex-1 px-6 pb-4 font-bold">
              <nav>
                <Menu
                  onClose={() => setSidebarOpen(false)}
                  onLogout={logoutHandler}
                />
              </nav>
            </div>
            <SubMenu onClose={() => setSidebarOpen(false)} />
          </div>
        </div>

        {/* header */}
        <div className="flex flex-1 flex-col lg:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 shadow bg-dorothy-gray">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dark-blue lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-end xl:justify-between px-4">
              <div className="hidden lg:flex lg:flex-1">
                <Breadcrumbs />
              </div>
              <div className="ml-4 flex items-center lg:ml-6">
                <div className="flex max-w-xs items-center pr-2">
                  <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-800 rounded-full">
                    <span className="font-normal text-sm text-white">
                      {userInfo && getFirstChars(userInfo.name)}
                    </span>
                  </div>
                  <span className="hidden lg:inline-flex pl-2 text-gray-700 font-medium text-sm">
                    {userInfo && userInfo.name}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <main>
            <div className="py-6 mx-auto w-full px-4 sm:px-6 md:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Main;
